<template>
  <div class="about">
    
    <router-view :class="typeTitle == true?'con-bianyi':'con'"/>
    <footerBottom></footerBottom>
  </div>
</template>
<script>
 import footerBottom from '@/components/footer.vue'
export default {
  components:{
     footerBottom
  },
  data(){
    return {
      screenWidth:null,
      typeTitle:false
    }
  },


  mounted(){
    console.log(0)
    if(window.localStorage.getItem("anjing-parent") == '编译系统大赛'){
      this.typeTitle = true

    }
    else {
      this.typeTitle = false
    }
    //   window.onresize = () => {
    //   return (() => {
    //     this.screenWidth = document.body.clientWidth;
    //     if( this.screenWidth<=800){

    //     }
    //   })();
    // };
  }
  
}
</script>
<style>
.con{
  min-height:calc(100vh - 270px)
}
.con-bianyi{
  min-height:calc(100vh - 200px)
}
.common-width{
  width:1240px !important;
  margin:0 auto !important

}
@media (max-width:1200px) {
.common-width{
  width:100% !important;


}

  
}
</style>
